//ProfileManager.js
import { list, downloadData, remove  } from 'aws-amplify/storage';
import { useUnityContextProvider } from '../../UnityServices/UnityContext';
import { useEffect, useCallback } from 'react';
import { uploadFileToS3 } from '../../utils/S3Utils';

const ProfileManager = () => {
    const { sendMessage, addEventListener, removeEventListener } = useUnityContextProvider();

  // Handle listing profiles
  const handleListProfiles = useCallback(async () => {
    try {
      const response = await list({ path: ({ identityId }) => `private/${identityId}/Slicer/Profiles/` });
      const profileNames = response.items.map(item => item.path.split('/').pop().replace('.json', ''));
      sendMessage('SlicerSettingsProfileManager', 'ListProfilesCallback', profileNames.join(','));
    } catch (error) {
      console.error('Error listing profiles:', error);
    }
  }, [sendMessage]);

  const handleSaveProfile = useCallback(async (profileName, profileData) => {
    try {
      // const profileName = `profile_${Date.now()}.json`; // Example naming scheme
      console.log('Saving profile:', profileName);
      console.log('Data:', profileData);
      console.log('Type:', typeof profileData);
      // Create a file from profileData
      const file1 = new File([new Blob([profileData])], profileName +".json");
      // Call the upload function (ensure it's implemented correctly)
      await uploadFileToS3(file1, `Slicer/Profiles/` );
      // Notify Unity that the profile was saved
      sendMessage('SlicerSettingsProfileManager', 'SaveProfileCallback', profileName);
    } catch (error) {
      console.error('Error saving profile:', error);
    }
  }, [sendMessage]);
  

  // Handle loading a profile
  const handleLoadProfile = useCallback(async (profileName) => {
    try {

      const downloadResult = await downloadData({ path: ({ identityId }) => `private/${identityId}/Slicer/Profiles/${profileName}.json` }).result;
      const profileData = await downloadResult.body.text(); // Assuming the profile is stored as JSON
      console.log('Loading profile:', profileName);
      console.log('Data:', profileData);
      sendMessage('SlicerSettingsProfileManager', 'LoadProfileCallback', profileData);
    } catch (error) {
      console.error('Error loading profile:', error);
    }
  }, [sendMessage]);

  // Handle deleting a profile
  const handleDeleteProfile = useCallback(async (profileName) => {
    try {
      await remove({ path: ({ identityId }) => `private/${identityId}/Slicer/Profiles/${profileName}.json` });
        console.log('Deleting profile:', profileName);
      sendMessage('SlicerSettingsProfileManager', 'DeleteProfileCallback', profileName);
    } catch (error) {
      console.error('Error deleting profile:', error);
    }
  }, [sendMessage]);

  const handleListMachineProfiles = useCallback(async () => {
    try {
      var path = `protected/Slicer/PrinterProfiles/`;
      const response = await list({ path: path });
      console.log('response:', response);
  
      var items = response.items.filter(element => element.path.endsWith('.json'));
  
      const profileNames = items.map(item =>
        item.path.split('/').pop().replace('.json', '')
      );
  
      console.log("profileNames:", profileNames.join(',')); 
      sendMessage('PrinterProfileManager', 'ListMachineProfilesCallback', profileNames.join(','));
    } catch (error) {
      console.error('Error listing profiles:', error);
    }
  }, [sendMessage]);
  


      // Handle loading a profile
  const handleLoadMachineProfile = useCallback(async (profileName) => {
    try {
      var path = `protected/Slicer/PrinterProfiles/${profileName}.json`;
      const downloadResult = await downloadData({path: path}).result;
      const profileData = await downloadResult.body.text(); // Assuming the profile is stored as JSON
      console.log('Loading profile:', profileName);
      console.log('Data:', profileData);
      sendMessage('PrinterProfileManager', 'LoadMachineProfileCallback', profileData);
    } catch (error) {
      console.error('Error loading profile:', error);
    }
  }, [sendMessage]);

  // React useEffect to register and clean up event listeners
  useEffect(() => {
    addEventListener('ListProfiles', handleListProfiles);
    addEventListener('SaveProfile', handleSaveProfile);
    addEventListener('LoadProfile', handleLoadProfile);
    addEventListener('DeleteProfile', handleDeleteProfile);
    addEventListener('ListMachineProfiles', handleListMachineProfiles);
    addEventListener('LoadMachineProfile', handleLoadMachineProfile);

    return () => {
      removeEventListener('ListProfiles', handleListProfiles);
      removeEventListener('SaveProfile', handleSaveProfile);
      removeEventListener('LoadProfile', handleLoadProfile);
      removeEventListener('DeleteProfile', handleDeleteProfile);
      removeEventListener('ListMachineProfiles', handleListMachineProfiles);
      removeEventListener('LoadMachineProfile', handleLoadMachineProfile);
    };
  }, [addEventListener, removeEventListener, handleListProfiles, handleSaveProfile, handleLoadProfile, handleDeleteProfile, handleListMachineProfiles, handleLoadMachineProfile]);

  return null;
};

export default ProfileManager;
