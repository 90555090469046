//ExportDataViewer.js
import React, { useState, useEffect } from 'react';
import { list, getProperties, getUrl } from 'aws-amplify/storage';
import '@aws-amplify/ui-react/styles.css';
import './DataViewer.css';

const ExportDataViewer = ({ cachedData, setCachedData }) => {
  const [data, setData] = useState(cachedData || { userDesignExports: [] });
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!cachedData) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [cachedData]);

  const fetchData = async () => {
    try {
      const response = await list({ path: ({ identityId }) => `private/${identityId}/` });
      var orderedItems = response.items;
      orderedItems.reverse();
      const exportData = await getExports(orderedItems);
      setData({ userDesignExports: exportData });
      setCachedData({ userDesignExports: exportData });
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const getExports = async (items) => {
    let exportsMap = new Map();

    for (const item of items) {
      if (!item.path) continue;
      const pathParts = item.path.split('/');
      if (item.path.includes('/exports/') && pathParts[pathParts.length - 1] === "brace.obj") {
        try {
          const property = await getProperties({ path: item.path });
          const patientId = property.metadata?.patientid || "Unknown";
          const folder = pathParts[pathParts.length - 2];
          const info = {
            patientId,
            foldername: folder,
            folderpath: pathParts.slice(0, pathParts.length - 1).join('/'),
            lastModified: item.lastModified,
          };
          exportsMap.set(folder, info);
        } catch (error) {
          console.log("Error getting patientId:", error);
        }
      }
    }

    return Array.from(exportsMap.values());
  };

  const handleDownload = async (path, fileName) => {
    try {
      const { url } = await getUrl({ path });
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      link.click();
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleFileSelection = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const renderFiles = (files) => {
    return (
      <div>
        {/* Header Row */}
        <div className="table-header">
          <span className="header-patient-id">Patient ID</span>
          <span className="header-date">Date</span>
          <span className="header-actions">Download</span>
        </div>
        {/* Data Rows */}
        {files.map((file) => (
          <div key={file.foldername} className="file-item">
            <span className="file-name">{file.patientId}</span>
            <span className="file-date">
              {file.lastModified ? new Date(file.lastModified).toLocaleString() : 'Unknown'}
            </span>
            <div className="action-buttons">
              <button
                className="action-button"
                onClick={() => handleDownload(`${file.folderpath}/brace.obj`, 'brace.obj')}
              >
                Brace
              </button>
              <button
                className="action-button"
                onClick={() => handleDownload(`${file.folderpath}/save.json`, 'save.json')}
              >
                Save
              </button>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="data-category-viewer">
      <h2 className="category-header">User Design Exports</h2>
      {loading ? (
        <p>Loading files...</p>
      ) : data.userDesignExports.length > 0 ? (
        renderFiles(data.userDesignExports)
      ) : (
        <p>No files found.</p>
      )}

      <div className="file-input-container">
        <input
          type="file"
          id="fileInput"
          className="file-input"
          onChange={handleFileSelection}
        />
      </div>
    </div>
  );
};

export default ExportDataViewer;
