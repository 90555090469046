import React, { useState, Fragment } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import AIDataViewer from './AIDataViewer';
import ExportDataViewer from './ExportDataViewer';
import './DataViewer.css';

const DataViewer = () => {
  const [activeTab, setActiveTab] = useState('export');
  const [exportData, setExportData] = useState(null); // Cache for Export data
  const [aiData, setAiData] = useState(null); // Cache for AI data
  const navigate = useNavigate();

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    navigate(`/files/${tab}`);
  };

  return (
    <Fragment>
      <div className="wrapper">
        <div className="header">
          <a href="http://kimedix.de">
            <img src={`${process.env.PUBLIC_URL}/img/kimedix-logo.png`} alt="Company Logo" className="logo" />
          </a>
        </div>
      </div>
      <hr style={{ width: '100%' }} />

      <div className="wrapper">
        <h2 className="reportHeader">My Files</h2>

        <nav className="tab-header">
          <button
            className={`tab-button ${activeTab === 'export' ? 'active' : ''}`}
            onClick={() => handleTabChange('export')}
          >
            Export Files
          </button>
          <button
            className={`tab-button ${activeTab === 'ai' ? 'active' : ''}`}
            onClick={() => handleTabChange('ai')}
          >
            AI Files
          </button>
        </nav>

        <div className="content">
          <Routes>
            <Route
              path="ai"
              element={<AIDataViewer cachedData={aiData} setCachedData={setAiData} />}
            />
            <Route
              path="export"
              element={<ExportDataViewer cachedData={exportData} setCachedData={setExportData} />}
            />
            <Route
              path="/"
              element={<ExportDataViewer cachedData={exportData} setCachedData={setExportData} />}
            />
          </Routes>
        </div>
      </div>

      <footer>
        <div>
          <p>
            To learn more about our scoliosis solutions, please visit our{' '}
            <a href="https://kimedix.de">website</a>.
          </p>
        </div>
      </footer>
    </Fragment>
  );
};

export default DataViewer;
