import React from 'react';
import { useUnityContextProvider } from './UnityContext';

const LoadGCode = () => {
    const { sendMessage } = useUnityContextProvider();

    const handleGCodeLoad = (event) => {
        const file = event.target.files[0]; // Get the first file
        console.log(file)

        if (file != null) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const fileString = e.target.result; // File contents as string
                console.log('File content:', fileString);
                sendMessage('GCodeVisualizer', 'LoadGCodeScene', fileString);
            }
            reader.onerror = (error) => {
                console.error('Error reading file:', error);
            };

            reader.readAsText(file); // Read file as text
        };
    }

    return (
        <div>
            <input
                type="file"
                id="gcodeInput"
                accept=".gcode"
                multiple
                onChange={handleGCodeLoad}
                style={{ display: 'none' }}
            />
        </div>
    );
};

export default LoadGCode;
