import { useEffect, Fragment } from 'react';
import { useUnityContextProvider } from './UnityContext';

class FileBrowserParams {
    constructor() {
        this.title = "";
        this.extension = "";
        this.callbackObject = "";
        this.callbackMethod = "";
        this.isCoroutine = false;
    }
}


function FileBrowser() {
    const { sendMessage } = useUnityContextProvider();

    const loadFile = (event) => {
        console.log(event.detail)

        const input = document.createElement("input");

        // Try mapping file params to FileBrowserParams
        let params = new FileBrowserParams();
        try {
            params.title = event.detail.title;
            params.extension = event.detail.extension;
            params.callbackObject = event.detail.callbackObject;
            params.callbackMethod = event.detail.callbackMethod;
            params.isCoroutine = event.detail.isCoroutine;
        } catch (e) {
            console.error("Failed to parse FileBrowserParams: " + e);
        }

        input.type = "file";
        input.accept = params.extension;
        input.style.display = "none";

        input.onchange = (event) => {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (event) => {
                    const contents = event.target.result;
                    const base64 = arrayBufferToBase64(new TextEncoder().encode(contents));
                    sendMessage(params.callbackObject, params.callbackMethod, base64);
                };
                reader.readAsText(file);
            }
        };

        input.click();
    };

    function arrayBufferToBase64(buffer) {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return btoa(binary);
    }

    useEffect(() => {
        window.addEventListener("LoadFile", loadFile);

        return () => {
            window.removeEventListener("LoadFile", loadFile);
        };
    }, []);

    return (<Fragment></Fragment>);
}

export default FileBrowser;
