import React, { useEffect, useCallback, useState } from 'react';
import { list, downloadData, getProperties } from 'aws-amplify/storage';
import { useUnityContextProvider } from '../../UnityServices/UnityContext';

const BraceImportDataManager = () => {
  const { sendMessage, addEventListener, removeEventListener } = useUnityContextProvider();

  // Utility to get exports similar to ExportDataViewer
  const getUserDesignExports = async () => {
    const response = await list({ path: ({ identityId }) => `private/${identityId}/` });
    const items = response.items || [];
    items.reverse();
    let exportsMap = new Map();
    for (const item of items) {
      var progress = (items.indexOf(item) / items.length) * 100;
      sendMessage("LoadingManager", "UpdateProgressBar", progress);
      if (!item.path) continue;
      const pathParts = item.path.split('/');
      if (item.path.includes('/exports/') && pathParts[pathParts.length - 1] === "brace.obj") {
        const { metadata } = await getProperties({ path: item.path });
        const patientId = metadata?.patientid || "Unknown";
        const folder = pathParts[pathParts.length - 2];
        const info = {
          patientId,
          foldername: folder,
          folderpath: pathParts.slice(0, pathParts.length - 1).join('/'),
          lastModified: item.lastModified,
        };
        exportsMap.set(folder, info);
      }
    }
    return Array.from(exportsMap.values());
  };

  const filterExportsWithinAWeek = (exports) => {
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    return exports.filter(exp => exp.lastModified >= oneWeekAgo);
  };

  // Handles listing exports
  const handleListExports = useCallback(async () => {
    try {
      const allExports = await getUserDesignExports();
      const recentExports = filterExportsWithinAWeek(allExports);
      const exportListString = recentExports.map(exp => {
        const dateStr = exp.lastModified ? formatTimestampToReadableString(exp.lastModified) : 'Unknown';
        return `${exp.patientId}|${dateStr}|${exp.folderpath}`;
      }).join(',');
      console.log("List of Exports (past week):", exportListString);
      sendMessage('BraceImporter', 'ListExportsCallback', exportListString);
    } catch (error) {
      console.error('Error listing exports:', error);
      sendMessage('BraceImporter', 'ListExportsCallback', "error");
    }
  }, [sendMessage]);

  function formatTimestampToReadableString(timestamp) {
    const date = new Date(timestamp);

    // Extract components
    const weekday = date.toLocaleString('en-US', { weekday: 'long' });
    const year = date.getFullYear();
    const month = date.toLocaleString('en-US', { month: 'short' });
    const day = date.getDate();
    const hours = date.getHours().toString().padStart(2, '0'); // Ensure 2-digit format
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const timeZone = date.toLocaleString('en-US', { timeZoneName: 'short' }).split(' ')[1];

    // Construct string without commas or vertical bars
    return `  ${month} ${day} ${year} ${hours}:${minutes}:${seconds}`;
}


  // Handles downloading a file
  const handleDownloadExport = useCallback(async (identifier) => {
    try {
      console.log("Downloading file from path:", identifier);
      const downloadResult = await downloadData({ path: identifier+ "/brace.obj", options: {onProgress: ({ transferredBytes, totalBytes }) => {
        const progress = transferredBytes / totalBytes;

                if (totalBytes) {
                    console.log(
                        `Upload progress ${Math.round(progress * 100)
                        } %`
                    );

                    sendMessage('LoadingManager', 'UpdateProgressBar', progress);
                }
            }
        }
        }).result;
      const fileContents = await downloadResult.body.text();
      console.log("Downloaded File Contents:", fileContents);
      sendMessage('BraceImporter', 'DownloadExportCallback', fileContents);
    } catch (error) {
      console.error('Error downloading export:', error);
      sendMessage('BraceImporter', 'DownloadExportCallback', "error");
    }
  }, [sendMessage]);

  useEffect(() => {
    addEventListener('ListExports', handleListExports);
    addEventListener('DownloadExport', handleDownloadExport);
    return () => {
      removeEventListener('ListExports', handleListExports);
      removeEventListener('DownloadExport', handleDownloadExport);
    };
  }, [addEventListener, removeEventListener, handleListExports, handleDownloadExport]);

  return null;
};

const ExportDataManagerTestButtons = ({ onListExports, onDownloadExport }) => {
  const [filePath, setFilePath] = useState('');

  return (
    <div style={{ padding: '20px', border: '1px solid black', marginTop: '10px' }}>
      <h3>Export Data Manager Test Buttons</h3>
      <div>
        <button onClick={onListExports}>Test: List Exports (Past Week)</button>
      </div>
      <div style={{ marginTop: '10px' }}>
        <input
          type="text"
          placeholder="Enter file path to download"
          value={filePath}
          onChange={(e) => setFilePath(e.target.value)}
          style={{ width: '300px', marginRight: '10px' }}
        />
        <button onClick={() => onDownloadExport(filePath)}>Test: Download File</button>
      </div>
    </div>
  );
};

export default BraceImportDataManager;
