import React, { useState, useEffect } from 'react';
import { list, getProperties, downloadData } from 'aws-amplify/storage';
import { useNavigate } from 'react-router-dom';
import DataStorage from '../../Services/DataStorageService';
import './DataViewer.css';

const AIDataViewer = ({ cachedData, setCachedData }) => {
  const [data, setData] = useState(cachedData || { cobbReports: [], boneAgeReports: [], braceTypeReports: [] });
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    if (!cachedData) {
      fetchData();
    } else {
      setLoading(false); // Stop loading if cachedData is available
    }
  }, [cachedData]);

  const fetchData = async () => {
    try {
      const response = await list({ path: ({ identityId }) => `private/${identityId}/AI/` });
      var orderedItems = response.items;
      orderedItems.reverse();
      const aiData = await categorizeAIReports(orderedItems);
      setData(aiData);
      setCachedData(aiData);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false); // Set loading to false once data is fetched
    }
  };

  const categorizeAIReports = async (items) => {
    let cobbReports = [];
    let boneAgeReports = [];
    let braceTypeReports = [];

    for (const item of items) {
      if (!item.path) continue;
      const pathParts = item.path.split('/');
      const reportType = pathParts[3];

      if (pathParts[pathParts.length - 1] === "Response.txt") {
        const reportData = {
          patientId: await getPatientId(item.path),
          foldername: pathParts[pathParts.length - 2],
          folderpath: pathParts.slice(0, pathParts.length - 1).join('/'),
          lastModified: item.lastModified,
        };
        
        if (reportType === 'cobbAI') cobbReports.push(reportData);
        if (reportType === 'boneAgeAI') boneAgeReports.push(reportData);
        if (reportType === 'braceTypeAI') braceTypeReports.push(reportData);
      }
    }

    return { cobbReports, boneAgeReports, braceTypeReports };
  };

  const getPatientId = async (path) => {
    try {
      const properties = await getProperties({ path });
      return properties.metadata?.patientid || "Unknown";
    } catch (error) {
      console.log("Error fetching patient ID:", error);
      return "Unknown";
    }
  };

  const openReportPage = async (path, component) => {
    console.log('Opening report:', path);
    const downloadResult = await downloadData({ path }).result;
    const data = await downloadResult.body.text();
    console.log('Report data:', data);  
    if(component === "bone-age") {
      DataStorage.setBoneAgeData(JSON.parse(data));
      window.open(`/bone-age-measurement`,"_blank");
    }
    else if( component === "brace-type") {
      DataStorage.setBraceTypePredictionData(JSON.parse(data));
      window.open(`/brace-prediction`, "_blank");
    }
    
  };

  const renderCategory = (files, categoryName, reportComponent) => (
    <div>
      <h3 className="category-header">{categoryName}</h3>
      {files.length > 0 ? (
        <div>
          {/* Header Row */}
          <div className="table-header">
            <span className="header-patient-id">Patient ID</span>
            <span className="header-date">Date</span>
            <span className="header-actions">Actions</span>
          </div>
          {/* Data Rows */}
          {files.map((file) => (
            <div
              key={`${file.folderpath}-${file.lastModified}-${reportComponent}`}
              className="file-item"
            >
              <span className="file-name">{file.patientId}</span>
              <span className="file-date">
                {file.lastModified ? new Date(file.lastModified).toLocaleString() : 'Unknown'}
              </span>
              <div className="action-buttons">
                <button
                  className="action-button"
                  onClick={() => openReportPage(`${file.folderpath}/Response.txt`, reportComponent)}
                >
                  View
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>No files found.</p>
      )}
    </div>
  );
  

  return (
    <div className="data-category-viewer">
      <h2 className="category-header" >AI Reports</h2>
      {loading ? (
        <p>Loading files...</p> // Show loading message while loading is true
      ) : (
        <>
          {renderCategory(data.boneAgeReports, "Bone Age AI Reports", "bone-age")}
          {renderCategory(data.braceTypeReports, "Brace Type AI Reports", "brace-type")}
        </>
      )}
    </div>
  );
};

export default AIDataViewer;
