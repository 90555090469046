class DataStorageService {
    constructor() {
        if (DataStorage == null) {
            DataStorage = this;
            this.data = {};
        }
        DataStorage = this;
        if (!window.DataStorage) {
            window.DataStorage = this;
            this.data = {};
        }
    }

    // Stores data in storage
    storeData(key, value) {
        this.data[key] = value;
        sessionStorage.setItem(key, JSON.stringify(value));
    }

    // Retrieves data from storage
    getData(key) {
        const data = sessionStorage.getItem(key);
        if (data) {
            return JSON.parse(data);
        }
        return null;
    }

    // Removes data from storage
    removeData(key) {
        delete this.data[key];
        sessionStorage.removeItem(key);
    }

    // Clears all data from storage
    clearData() {
        this.data = {};
        sessionStorage.clear();
    }

    // Set and get methods for various types of data
    setPatientId(value) {
        this.storeData('patientId', value);
    }

    getPatientId() {
        return this.getData('patientId');
    }

    // Other set and get methods for data
    setCobbData(value) {
        this.storeData('cobbData', value);
    }

    setBraceTypePredictionData(value) {
        this.storeData('braceTypePredictData', value);
    }

    setBoneAgeData(value) {
        this.storeData('boneAgeData', value);
    }

    getBraceTypePredictionData() {
        return this.getData('braceTypePredictData');
    }

    getBoneAgeData() {
        return this.getData('boneAgeData');
    }

    getCobbData() {
        return this.getData('cobbData');
    }
}

// Initialize the singleton instance
var DataStorage = new DataStorageService();
export default DataStorage;