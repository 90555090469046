import React, { Fragment, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import PostImageCobbAI from '../UnityServices/PostImageCobbAI.js';
import UploadScan from '../UnityServices/UploadScan';
import UploadXRay from '../UnityServices/UploadXRay';
import UploadXRayForBoneAge from '../UnityServices/UploadXRayForBoneAge.js';
import UploadXRayForCobb from '../UnityServices/UploadXRayForCobb.js';
import LoadGCode from '../UnityServices/LoadGCode.js';

import ImportSave from '../UnityServices/ImportSave';
import LoadAdditionalData from '../UnityServices/LoadAdditionalData';
import FileBrowser from '../UnityServices/FileBrowser.js';

import { Unity } from "react-unity-webgl";
import { useGlobalState } from '../GlobalStateContext';
import { AutoSaveService } from '../UnityServices/AutoSave';
import { FullscreenButton } from '../UnityServices/FullScreenButton';
import { GetAssetBundleURL } from '../UnityServices/GetAssetBundleURL';
import GetSamplePatients from '../UnityServices/GetSamplePatients.js';
import GetUserInfo from '../UnityServices/GetUserInfo.js';
import { ListAvailableBraces } from "../UnityServices/ListAvailableBraces.js";
import { SplashScreenController } from "../UnityServices/SplashScreenController.js";
import { UnityProvider } from "../UnityServices/UnityContext";
import MyComponent from "./figma2reacttest";
import FileExplorer from "./FileExplorer";
import TempFileExplorer from "./tempFileExplorer";
import ErrorReport from '../UnityServices/ErrorReport.js';
import UserTimeTracker from './UserTimeTracker.js';
import ProfileManager from './Slicer/ProfileManager.js';
import BraceImportDataManager from './Slicer/BraceImportDataManager.js';

function UnityApp({ unityContextValue }) {
    const [isBrowserOpen, setBrowserOpen] = useState(false);
    const fileInputRef = useRef(null); // Create a ref for the file input
    const popupWindow = useRef(null);
    const { splashScreenOver, setSplashScreenOver } = useGlobalState();
    // New state to track if save exists
    const {
        unityProvider,
        isLoaded,
        loadingProgression,
        sendMessage,
        requestFullscreen,
        addEventListener,
        removeEventListener,
    } = unityContextValue;

    const openFileBrowserWindow = () => {
        const windowFeatures = "width=750,height=400,scrollbars=yes";
        popupWindow.current = window.open("", "", windowFeatures);
        popupWindow.current.document.title = "File Browser";

        // Create a new div element in the new window and mount the FileBrowser component
        const container = popupWindow.current.document.createElement("div");
        popupWindow.current.document.body.appendChild(container);

        // Add the necessary CSS styles
        const link = popupWindow.current.document.createElement("link");
        link.rel = "stylesheet";
        link.href = "@aws-amplify/ui-react/styles.css"; // Include the correct path to the CSS file
        popupWindow.current.document.head.appendChild(link);

        // Use ReactDOM.createRoot instead of ReactDOM.render
        const root = ReactDOM.createRoot(container);
        root.render(<FileExplorer onClose={() => popupWindow.current.close()} />);
        //root.render(<MyComponent />);
    };

    const openNewFileBrowserWindow = () => {
        const windowFeatures = "width=750,height=400,scrollbars=yes";
        popupWindow.current = window.open("", "", windowFeatures);
        popupWindow.current.document.title = "File Browser";

        // Create a new div element in the new window and mount the FileBrowser component
        const container = popupWindow.current.document.createElement("div");
        popupWindow.current.document.body.appendChild(container);

        // Add the necessary CSS styles
        const link = popupWindow.current.document.createElement("link");
        link.rel = "stylesheet";
        link.href = "@aws-amplify/ui-react/styles.css"; // Include the correct path to the CSS file
        popupWindow.current.document.head.appendChild(link);

        // Use ReactDOM.createRoot instead of ReactDOM.render
        const root = ReactDOM.createRoot(container);
        root.render(<MyComponent />);
    };

    const openTempFileBrowserWindow = () => {
        const windowFeatures = "width=750,height=400,scrollbars=yes";
        popupWindow.current = window.open("", "", windowFeatures);
        popupWindow.current.document.title = "File Browser";

        // Create a new div element in the new window and mount the FileBrowser component
        const container = popupWindow.current.document.createElement("div");
        popupWindow.current.document.body.appendChild(container);

        // Add the necessary CSS styles
        const link = popupWindow.current.document.createElement("link");
        link.rel = "stylesheet";
        link.href = "@aws-amplify/ui-react/styles.css"; // Include the correct path to the CSS file
        popupWindow.current.document.head.appendChild(link);

        // Use ReactDOM.createRoot instead of ReactDOM.render
        const root = ReactDOM.createRoot(container);
        root.render(<TempFileExplorer />);
    };

    // Trigger file input when button is clicked
    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    // Ensure the Unity instance resizes correctly
    const handleResize = () => {
        const container = document.getElementById("unityInstance");
        const aspectRatio = 16 / 9;

        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;
        let width, height;

        if (viewportWidth / viewportHeight > aspectRatio) {
            width = viewportHeight * aspectRatio;
            height = viewportHeight;
            //container.style.left = ((viewportWidth - width) / 2) + 'px';
            console.log(width);
            console.log(viewportWidth);
        } else {
            // If viewport is taller than the aspect ratio
            width = viewportWidth;
            height = viewportWidth / aspectRatio;
            //container.style.left = 0;
        }

        // Apply the calculated width and height to the container
        container.style.width = `${width}px`;
        container.style.height = `${height}px`;
    }// Check if the save file exists on component mount

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [
        addEventListener,
        removeEventListener,
        unityProvider,
        handleResize,
    ]);

    const progressBarStyle = {
        width: `${loadingProgression * 100}%`,
        height: "30px",
        backgroundColor: "#4e587c",
    };

    const parentStyle = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw", // Full viewport width
        height: "100vh", // Full viewport height
        backgroundColor: "#f3f3f3",
    };

    const containerStyle = {
        display: "flex",
        flexDirection: "column", // Arrange children in a column
        alignItems: "center", // Center items horizontally
        width: "30%",
        backgroundColor: "#fff",
        padding: "20px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
    };

    const logoStyle = {
        marginBottom: "20px", // Add space between the logo and the progress bar
        width: "100%",
    };

    return (
        <Fragment>
            <UnityProvider value={unityContextValue}>
                <FullscreenButton />
                <ErrorReport />
                <GetAssetBundleURL />
                <ListAvailableBraces />
                <SplashScreenController />
                <BraceImportDataManager/>
                <div>
                    <Fragment>
                        <ProfileManager />
                        <UserTimeTracker />
                        <PostImageCobbAI />
                        <UploadScan />
                        <UploadXRay />
                        <UploadXRayForCobb />
                        <UploadXRayForBoneAge />
                        <ImportSave />
                        <GetUserInfo />
                        <GetSamplePatients />
                        <LoadAdditionalData />
                        <LoadGCode />
                        {!isLoaded && (
                            <div style={parentStyle}>
                                {/* <p style={{ textAlign: 'center' }}>Loading Application... {Math.round(loadingProgression * 100)}%</p> */}
                                <div style={containerStyle}>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/img/kimedix-logo.png`}
                                        alt="Company Logo"
                                        style={logoStyle}
                                    />
                                    <div style={{ width: "100%", backgroundColor: "#f3f3f3" }}>
                                        <div id="myBar" style={progressBarStyle} />
                                    </div>
                                </div>
                            </div>
                        )}
                        {(isLoaded && !splashScreenOver) && (
                            <div style={parentStyle}>
                                {/* <p style={{ textAlign: 'center' }}>Loading Application... {Math.round(loadingProgression * 100)}%</p> */}
                                <div style={containerStyle}>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/img/kimedix-logo.png`}
                                        alt="Company Logo"
                                        style={logoStyle}
                                    />
                                </div>
                            </div>
                        )}
                        {(
                            <div
                                className="unity-container"
                                id="unityContainer"
                                style={{
                                    display: (isLoaded && splashScreenOver) ? 'block' : 'none'
                                }}
                            >
                                <Unity
                                    unityProvider={unityProvider}
                                    className="unity-instance"
                                    id="unityInstance"
                                />
                            </div>)}

                        {/* Start services after the app is loaded*/}
                        {(isLoaded && splashScreenOver) && (
                            <div>
                                <AutoSaveService />
                                <FileBrowser />
                            </div>
                        )}
                        
                    </Fragment>
                </div>
            </UnityProvider>
        </Fragment>
    );
}
export default UnityApp;
